.cellDisabled {
  background-color: #f0eeee;
  cursor: default;
}

.cellEnabled {
  cursor: pointer;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;

  .progress {
    margin-bottom: 20px;
  }
}