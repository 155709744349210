.enrolmentContent {
  height: calc(100% - 108px);
  padding: 10px 30px 0;
  
  .tabs {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  .tabContent {
    .infoContent {

      // Responsive
      @media screen and (max-width: 425px) {
        flex-direction: column!important;
      }
    }

    // Responsive
    @media screen and (max-width: 425px) {
      padding-bottom: 14rem;
    }
  }

  .text {
    font-size: 16px;
    color: #202020;
    margin-top: 10px;

    a {
      color: #006EB6;
    }
  }

  .subText {
    font-size: 12px;
    color: #7E7E7E;
  }
}

.buttonsBar {
  width: 100%;
  background-color: white;
  padding: 2rem;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center;

  // Responsive
  @media screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin-bottom: 2rem;
      margin-right: 0!important;
      margin-left: 0!important;
    }
  }
}
