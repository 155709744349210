/*On-boardings Styles*/
.on-boarding-item {
  .on-boarding-status {
    min-width: 5rem;
  }
}

.beneficiaries-table tbody tr td {
  &:hover {
    cursor: pointer;
  }
}

.on-boarding-detail-card {
  @extend .jr-card;

  padding: 0;

  .on-boarding-detail-loading {
    min-height: 362px;
    vertical-align: middle;

    .spinner {
      margin-top: 140px;
    }
  }

  .on-boarding-card-header {
    padding: $jr-card-padding $jr-card-padding;
    //background-color: theme-color-level("primary", 1);
    @include border-radius($border-radius $border-radius 0 0);

    @include media-breakpoint-up(lg) {
      @include border-radius($border-radius 0 0 $border-radius);
    }
      & h3 a {
        color: white;
      }

      & .jr-badge-up {
        @include display-flex(flex, row, nowrap);
        @include align-items(center);
        @include justify-content(center);
        //width: 45px !important;
        //height: 45px !important;
        padding: 5px;
        font-size: ($font-size-lg + 0.25rem);
        color: $white;
        @include border-radius($border-radius-circle);
        position: absolute;
        right: $size-20;
        @include box-shadow($avatar-shadow);
        bottom: -22px;
        z-index: 1;

      & .zmdi-mail-send {
        padding-left: 4px;
      }

      @media screen and (max-width: 1366px) {
        //width: 40px !important;
        //height: 40px !important;
        font-size: ($font-size-lg + 0.125rem);
        bottom: -20px;
      }
    }

    .on-boarding-header-buttons {
      margin-bottom: -$size-60;;
    }
  }

  & .on-boarding-card-body {
    padding: 1rem;
  }
}
