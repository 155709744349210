/*Theme UNJSPF Styles*/
$app-primary-blue: #006EB6 !default;
$secondary-blue: #FF6E40;

$text-color: #202020;

$sidebar-light-gray: #F2F4F7;
$sidebar-gray: #565656;
$sidebar-blue: rgba($app-primary-blue, 0.25) !default;
$sidebar-text-blue: #868e96 !default;

//-Base-scss
.unjspf .right-arrow {
  color: $app-primary-blue;

  &:after {
    color: $app-primary-blue;
  }
}

//- Bootstrap file Style
.unjspf {
  a,
  .card-link,
  .jr-link,
  .jr-link.text-primary {
    color: $app-primary-blue;

    &:focus,
    &:hover {
      color: darken($app-primary-blue, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-blue !important;
  }

  & .page-link {
    color: $app-primary-blue;

    &:focus,
    &:hover {
      color: $app-primary-blue;
    }
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-blue !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $app-primary-blue;
    border-color: $app-primary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-blue, 5%) !important;
      border-color: darken($app-primary-blue, 5%) !important;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #BBDEFB !important;
    }

    &.lighten-3 {
      background-color: #90CAF9 !important;
    }

    &.lighten-2 {
      background-color: #64B5F6 !important;
    }

    &.lighten-1 {
      background-color: #42A5F5 !important;
    }

    &.darken-1 {
      background-color: #1E88E5 !important;
    }

    &.darken-2 {
      background-color: #1976D2 !important;
    }

    &.darken-3 {
      background-color: #1565C0 !important;
    }

    &.darken-4 {
      background-color: #0D47A1 !important;
    }

    &.accent-1 {
      background-color: #82B1FF !important;
    }

    &.accent-2 {
      background-color: #448AFF !important;
    }

    &.accent-3 {
      background-color: #2979FF !important;
    }

    &.accent-4 {
      background-color: #2962FF !important;
    }
  }
}

//Secondary
.unjspf {
  .jr-link.text-secondary {
    color: $secondary-blue;

    &:focus,
    &:hover {
      color: darken($secondary-blue, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-blue !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-blue !important;
    color: $white !important;
  }

  & .btn-secondary {
    background-color: $secondary-blue;
    border-color: $secondary-blue;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-blue, 5%) !important;
      border-color: darken($secondary-blue, 5%) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.unjspf .app-main-header {
  background-color: $app-primary-blue !important;

  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.unjspf .color-theme-header {
  background-color: $app-primary-blue;
}

//_sidebar.scss
.unjspf .side-nav {
  background-color: $white !important;
  color: $sidebar-gray !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));
}

.unjspf ul.nav-menu li:not(.open) a:not(:hover):not(:focus) {
  color: $app-primary-blue;

  .nav-text {
    color: $sidebar-text-color;
  }
}

.unjspf ul.nav-menu {
  & .nav-header {
    color: $sidebar-text-blue;
  }

  & li.menu + .nav-header {
    border-color: $gray-400;
  }
}

.unjspf ul.nav-menu > li.open > button,
.unjspf ul.nav-menu > li.open > a {
  background-color: $sidebar-gray;
  color: $white;
  border-radius: 8px;
}

.unjspf ul.nav-menu > li:not(.open) > button:hover,
.unjspf ul.nav-menu > li:not(.open) > button:focus,
.unjspf ul.nav-menu > li:not(.open) > a:hover,
.unjspf ul.nav-menu > li:not(.open) > a:focus {
  background-color: $sidebar-light-gray;
  color: $text-color;
  border-radius: 8px;
}

.unjspf ul.nav-menu li.menu.open > a {
  border-color: $app-primary-blue;
}

.unjspf ul.nav-menu li.menu > button:focus:before,
.unjspf ul.nav-menu li.menu > button:hover:before,
.unjspf ul.nav-menu li.menu > a:focus:before,
.unjspf ul.nav-menu li.menu > a:hover:before {
  color: $app-primary-blue;
}

.unjspf ul.nav-menu li ul {
  background-color: $white;
}

.unjspf ul.nav-menu li.menu > button:before,
.unjspf ul.nav-menu li.menu > a:before {
  color: $sidebar-text-blue;
}

.unjspf ul.nav-menu li.menu.open > a:before,
.unjspf ul.nav-menu li.menu > a:focus:before,
.unjspf ul.nav-menu li.menu li > a:hover:before,
.unjspf ul.nav-menu li.menu.open > button:before,
.unjspf ul.nav-menu li.menu > button:focus:before,
.unjspf ul.nav-menu li.menu li > button:hover:before {
  color: $app-primary-blue;
}

.unjspf ul.nav-menu li.menu .sub-menu li > a:before {
  color: $sidebar-text-blue;
}

.unjspf ul.nav-menu li.menu.open .sub-menu li > a:after {
  background-color: $app-primary-blue;
}

.unjspf ul.nav-menu li.menu.open .sub-menu li > a.active {
  background-color: $sidebar-blue;
  color: $app-primary-blue;
}

.unjspf ul.nav-menu li.menu .sub-menu li > a:hover,
.unjspf ul.nav-menu li.menu .sub-menu li > a:focus,
.unjspf ul.nav-menu li.menu.open .sub-menu li > a.active:before,
.unjspf ul.nav-menu li.menu .sub-menu li > a:focus:before,
.unjspf ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: $white;
  color: $app-primary-blue;
}

/*Header top Navbar Styles*/
.unjspf .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-blue;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-blue;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-blue;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-blue;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-blue;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-blue;
    }
  }
}

.unjspf .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-blue;
    }
  }
}

.unjspf .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.unjspf .header-notifications .app-notification {
  & .jr-list-link {

    // @include hover-focus-active {
    //   color: $app-primary-blue;
    //   border-color: $app-primary-blue;
    // }
  }
}

//_app-module.scss
.unjspf .module-nav {
  & li a {
    &.active {
      border-color: $app-primary-blue;
    }
  }
}

//_calendar.scss
.unjspf .rbc-event {
  background-color: $app-primary-blue;
}

.unjspf .rbc-event.rbc-selected {
  background-color: darken($app-primary-blue, 10%);
}

.unjspf .rbc-slot-selection {
  background-color: rgba($app-primary-blue, 0.7);
}

.unjspf .rbc-toolbar button:active,
.unjspf .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.unjspf .rbc-toolbar button:active:hover,
.unjspf .rbc-toolbar button.rbc-active:hover,
.unjspf .rbc-toolbar button:active:focus,
.unjspf .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.unjspf .rbc-toolbar button:focus {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

.unjspf .rbc-toolbar button:hover {
  background-color: rgba($app-primary-blue, 0.9);
  border-color: $app-primary-blue;
}

//_chat.scss
.unjspf .chat-sidenav-title {
  color: $app-primary-blue;
}

.unjspf .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-blue, 45%);
  }
}

//_dashboard.scss
.unjspf .contact-list {
  & i {
    color: $app-primary-blue;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.unjspf .Collapsible__trigger {
  background: $app-primary-blue;
}

.unjspf .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-blue;
  }
}

//_login.scss
.unjspf .login-content .form-control {
  &:focus {
    border-color: $app-primary-blue;
  }
}

//_portfolio.scss
.unjspf .filter-with-bg-color ul li {
  .jr-link {
    border-color: $app-primary-blue;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-blue;
    }
  }
}

//_card.scss
.unjspf .profile-intro {
  & .icon {
    color: $app-primary-blue;
  }
}

.unjspf .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-blue;
  }
}

//_tables.scss
.unjspf .actions {
  color: $secondary-blue;
}

.unjspf .table-hover tbody tr:hover {
  background-color: rgba($app-primary-blue, 0.075);
}

//Border Color

.unjspf .border-primary {
  border-color: $app-primary-blue !important;
}

// login page content

.unjspf .app-logo-content {
  background-color: $gray-300;
}

.unjspf .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-blue;
    color: $app-primary-blue;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-blue;
    }
  }
}

/*Button Group Styles*/
.unjspf .btn-group,
.unjspf .btn-group-vertical {
  > .jr-btn {
    &.active {
      background-color: $app-primary-blue;
      border-color: $app-primary-blue;
    }

  }

  > .jr-flat-btn {
    background-color: transparent;
    border-color: transparent;

    &.active {
      background-color: transparent;
      color: $app-primary-blue;

      &:hover,
      &:focus {
        background-color: rgba($app-primary-blue, 0.12);
        color: $app-primary-blue;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.unjspf .jr-fillchart-btn-close,
.unjspf .jr-onchart .jr-badge-up,
.unjspf .jr-task-list-item:hover .jr-text-hover {
  color: $app-primary-blue;
}

.unjspf .jr-entry-title:before {
  background-color: $app-primary-blue;
}

.unjspf .jr-card-ticketlist {
  & .jr-task-list-item:hover .jr-task-item-title,
  .jr-link {
    color: $app-primary-blue;
  }
}

.unjspf .slick-dots li.slick-active button:before {
  border-color: $app-primary-blue;
}

//Nav Styles
.unjspf .nav-pills .nav-link.active,
.unjspf .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-blue;
}

// Gradient Color Class
.unjspf .bg-gradient-primary {
  @include gradient-directional($app-primary-blue, lighten($app-primary-blue, 16%), 0deg);
}

.unjspf .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-blue, 10%), lighten($app-primary-blue, 16%), 70%, 100%);
}

//Profile style
.unjspf .jr-profile-banner {
  background-color: $app-primary-blue;
  color: $white;

  & .jr-link .profile-menu-item a {
      color: $white;
      text-transform: none;


    &:hover,
    &:active,
    &:focus {
      text-decoration: none !important;
    }
  }
}

.unjspf .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-blue;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}
