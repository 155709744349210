.container {
  height: 100%;
  align-content: center;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:not(:last-child) {
      padding-bottom: 10px;
    }

    .imageWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      max-width: calc(100% - 20px);
      padding: 10px 150px;

      .expandButton {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.1s linear;
        position: absolute;
        bottom: 18px;
        right: 168px;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        cursor: pointer;
      }

      &:hover {
        .expandButton {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.scoresContainer {
      padding: 30px 0;

      .totalScoreContainer {
        padding: 24px 64px;

        .totalScoreWrapper {
          width: 180px;
          height: 180px;
  
          .totalScoreTitle {
            color: #565656;
            font-size: 14px;
            line-height: 140%;
          }
          
          .totalScoreValue {
            color: #202020;
            font-size: 24px;
            font-weight: 700;
          }
        }
      }
  
      .individualScoresContainer {
        height: 100%;
        padding: 24px 64px;

        @media (min-width: 960px) {
          border-left: 1px solid #7E7E7E4D;
        }
      }
    }
  }
}