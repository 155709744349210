.drawer {
  display: flex;
  justify-content: center;
  align-items: end;
}

.drawerBox {
  width: 500px;

  // Responsive
  @media screen and (max-width: 425px) {
    width: 100%;
    min-width: 400px;
  }
}
