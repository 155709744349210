.dialogContent {
  padding: 0 40px;

  .contentTitle {
    font-size: 18px;
    font-weight: 600;
    margin: 40px 0 24px;
  }

  .contentForm {
    padding-left: 10px;

    .otherReasons {
      ::placeholder {
        font-size: 16px;
      }
    }

    .invalidReason {
      margin-top: 10px;
      text-transform: none;
    }
  }
}

.actionsBox {
  display: flex;
  align-items: center;

  .actionButton {
    font-size: 18px;
    text-transform: none;

    &.nextButton {
      margin-left: auto;
    }
  }
}