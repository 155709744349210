.progressContainer {
  &:not(:last-child) {
    margin-bottom: 18px;
  }
  
  .labelContainer {
    display: flex;
    margin-bottom: 10px;

    .label {
      font-size: 14px;
      line-height: 140%;
      color: #565656;
    }

    .value {
      margin-left: auto;
      color: #7E7E7E;
    }
  }
}