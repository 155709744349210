/*Breadcrumb Styles*/
.breadcrumb {
  @include border-radius($border-radius-sm);
}

.breadcrumb-item {
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0;
}