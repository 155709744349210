.title {
  margin-top: 60px;
  margin-bottom: 10px;
  color: #565656;
  font-size: 18px;
}

.input {
  width: 100%;
  color: #666;
  input {
    color: #202020;
    opacity: 0.75;
  }
}

.textarea {
  width: 100%;
  height: 11rem;
  margin-top: .3rem;
  color: #666;
  ::placeholder {
    font-size: 16px;
  }

  textarea {
    word-break: break-word;
    color: #202020;
    opacity: 0.75;
  }
}
