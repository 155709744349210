$SLOT_HEIGHT: 30px;

.scheduleView {
	.fc-toolbar {
		.fc-button-group .fc-button,
		.fc-button {
			border-radius: 4px;
			background-color: rgba(126, 126, 126, 0.1);
			border: none;
			color: #7E7E7E;

			&:not(:last-child) {
				margin-right: 5px;
			}
		}

		.fc-button-group .fc-button {
			&.fc-prev-button,
			&.fc-next-button,
			&.fc-button-active {
				background-color: #565656;
				border-color: #565656;
				color: white;
			}
		}

		.fc-toolbar-title {
			font-size: 14px;
			color: #565656;
		}
	}

	.fc-scrollgrid-section-body {
		.fc-day.fc-day-today {
			background-color: rgba(126, 126, 126, 0.1);
		}

		.fc-event {
			cursor: pointer;
			color: #565656;

			.fc-list-event-graphic {
				padding: 8px 0;
			}
		}
	}

	.fc-timeGridWeek-view,
	.fc-timeGridDay-view {
		.fc-scrollgrid, th, td {
			// Remove all borders
			border: none;
		}

		.fc-scrollgrid-section-header .fc-scroller {
			overflow: hidden !important;
			margin-bottom: 5px;

			.fc-col-header-cell {
				// border-bottom: 1px dashed #ddd;
				
				.fc-col-header-cell-cushion {
					color: black;
					padding: 4px;
				}
			}
		}

		.fc-scrollgrid-section-body {
			.fc-scroller {
				// Hide vertical scroller 
				margin-right: -17px;
			}

			.fc-timegrid-body {
				.main-hour-slot {
					.fc-timegrid-slot {
						&.fc-timegrid-slot-label {
							font-size: 12px;
						}
						&.fc-timegrid-slot-lane {
							border-top: 1px dashed #DDD;
						}
					}
				}

				.fc-timegrid-slot {
					height: $SLOT_HEIGHT;
					line-height: $SLOT_HEIGHT;

					&.fc-timegrid-slot-label {
						color: #7E7E7E;
						opacity: 0.5;
						font-size: 10px;

						.fc-timegrid-slot-label-frame {
							margin-top: calc(#{$SLOT_HEIGHT} * -1);
						}
					}
				}

				.fc-timegrid-cols {
					.fc-timegrid-col {
						.fc-timegrid-event-harness {
							margin: 3px;

							.fc-event {
								border-radius: 4px;
								border: none;
								box-shadow: none;

								.fc-event-main {
									padding: 0;

									.fc-event-title {
										padding-left: 6px;
										white-space: nowrap;
										text-overflow: ellipsis;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}