/*Footer Styles*/

.app-footer {
  position: relative;
  margin-top: auto;

  .separator {
    width: 100%;
    border-bottom: 1px solid #7E7E7E;
    margin: 24px 0;
  }
}