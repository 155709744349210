/* Beneficiary Styles*/
@-webkit-keyframes showDCEAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  80% {
    transform: scale(0.95);
    -webkit-tranform: scale(0.95);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes showDCEAlert {
  0% {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
  }
  80% {
    transform: scale(0.95);
    -webkit-tranform: scale(0.95);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.card-menu-icon {
  min-width: 40px !important;
}
