.buttonsBox {
  width: 100%;
  padding: 2rem 0;
  box-shadow: 0 0 30px -2px lightgrey;

  .buttonsContainer {
    padding-right: 2rem;
  }
  .buttonContainer {
    display:flex;
    justify-content:center;
    align-items: center;
  }

  .saveBtn:not(:disabled) {
    background-color: #006EB6;
  }

  .cancelBtn {
    background: transparent;
  }
}

