.stepperContainer {
  .progress {
    color: #006EB6;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
  }

  .stepsContainer {
    display: flex;

    .step {
      width: 35px;
      height: 6px;
      border-radius: 100px;
      background-color: #006EB6;
  
      &.disabled {
        opacity: 0.3;
      }
  
      &:not(:first-child) {
        margin-left: 6px;
      }
    }
  }
}