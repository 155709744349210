.username-icon{
  color: $light-gray;
}

.manager-user-name {
  margin-bottom: 2px;
  font-weight: 400;
}


