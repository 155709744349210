.enrolmentImageBox {
  padding: 4rem 0;

  .h1Title {
    font-size: 34px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .h1TitleSpan {
      margin: auto;
    }
  }
}
