.widget {
  padding: 40px 25px;
}

.userInfo,
.userForm {
  .field {
    padding: 0 15px;
    margin-bottom: 70px;

    .input {
      margin-top: 24px;

      &.inputDisabled::before {
        border-bottom-style: solid;
        opacity: 0.38;
      }
    }

    .label {
      transform: translate(0, 1.5px) scale(0.875);
    }

    .fieldLabel {
      font-size: 14px;
      color: #0000008a;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;

    .action {
      padding: 12px 16px;
      text-transform: none;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .note {
    display: flex;
    align-items: center;
    padding: 15px 15px 30px;
    font-size: 14px;
    color: #0000008a;

    .icon {
      padding-right: 5px;
    }
  }
}

.usersList {
  .addUserButton {
    text-transform: none;
  }
}