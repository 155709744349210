.dialog {

  .dialogPaper {
    width: 700px;

    .dialogTitle {
      display: flex;
      align-items: center;
      padding: 20px;
  
      .title {
        font-size: 20px;
        font-weight: 700;
      }
  
      .closeButton {
        margin-left: auto;
        padding: 10px;
        color: #000;
  
        .closeIcon {
          width: 20px;
          height: 20px;
        }
      }
    }
  
    .dialogContent {
      padding: 30px 20px;
    }
  
    .dialogActions {
      display: block;
      padding: 20px;
    }
  }
}