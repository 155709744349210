.filtersContainer {
  height: calc(100vh - 107px);
  overflow-y: auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: start;
  flex-direction: column;

  .filterTitle {
    font-weight: 700;
    font-size: 24px;
    color: #202020;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .container {

  }
  .closeContainer {
    color: #006EB6;
    font-size: 16px;
    float: right;
    cursor: pointer
  }
  .listContainer {

  }
}
